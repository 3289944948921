@media print {
    body {
      font-size: 12pt; /* Adjust font size for print */
      color: #000; /* Ensure text is black for readability */
      background: none; /* Remove background colors/images */
    }
  
    .no-print {
      display: none; /* Hide elements not needed for print */
    }
  
    .print-friendly {
      display: block; /* Ensure specific elements are displayed */
      width: 100%; /* Adjust width to fit the print layout */
    }
  
    /* Ensure graphics are formatted correctly */
    img {
      max-width: 100%; /* Ensure images fit within the page */
      height: auto;
    }
  
    /* Adjust layout for print */
    .container {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .big-container {
        height: 800px;
    }
  
    /* Avoid breaking inside elements */
    .avoid-break {
      page-break-inside: avoid;
      break-inside: avoid; /* For compatibility with more browsers */
    }
  
    /* Additional styling adjustments for print */
    table {
      width: 100%;
      border-collapse: collapse;
    }
  
    th, td {
      border: 1px solid #000;
      padding: 5px;
    }


    .ratingCardsContainer {
        flex-wrap: wrap;
    }

    .counsellorRatingContainer {
        flex-direction: column;
    }

    .print-footer {
      height: 1500px;
    }
    
  }
  